/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import posed from 'react-pose';
import { connect } from 'react-redux';
import Cart from 'mdi-react/CartIcon';
import { updateProductList } from '../../actions/productListActions';
import { ReactComponent as Delete } from '../../styles/icons/delete.svg';
import './styles.scss';

const Icon = posed.div({
  hovering: {
    top: '-1px',
    color: '#ffffff',
    backgroundColor: '#000000',
    boxShadow: '0 3px 3px rgba(36, 43, 73, 0.5)',
    transition: { duration: 100 },
  },
  notHovering: {
    top: '1px',
    color: '#242b49',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 0 rgba(36, 43, 73, 0.5)',
    transition: { duration: 100 },
  },
  notHoveringSelected: {
    top: '1px',
    color: '#ffffff',
    backgroundColor: '#000000',
    boxShadow: '0 0 0 rgba(36, 43, 73, 0.5)',
    transition: { duration: 100 },
  },
});
const Del = posed.div({
  picked: {
    opacity: 1,

  },
  notPicked: {
    opacity: 0,
  },
});

class Productbutton extends Component {
  constructor() {
    super();
    this.state = {
      hover: false,
      picked: false,
    };
  }

  componentDidMount() {
    const { productList, pick, id } = this.props;
    if (productList.length > 0) {
      productList.forEach(product => {
        if (product.id === id) {
          this.setState({
            picked: true,
          });
          pick(true);
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { productList, pick, id } = this.props;
    if (nextProps.productList !== productList) {
      let picked = false;
      nextProps.productList.forEach(product => {
        if (product.id === id) {
          picked = true;
        }
      });
      this.setState({
        picked,
      });
      pick(picked);
    }
  }

  mouseEnter = outOfStock => {
    if (typeof outOfStock === 'undefined' || outOfStock === false) {
      this.setState({
        hover: true,
      });
    }
  }

  mouseLeave = () => {
    this.setState({
      hover: false,
    });
  }

  onClick = outOfStock => {
    const {
      productList, id, product, dispatch,
    } = this.props;
    const { picked } = this.state;
    if (typeof outOfStock === 'undefined' || outOfStock === false) {
      const pickedNew = !picked;
      this.setState({
        picked: pickedNew,
      }, () => {
        if (!picked) {
          const list = productList ? [...productList] : [];
          const newProduct = { ...product, id };
          list.push(newProduct);
          dispatch(updateProductList(list));
        } else {
          let list = productList ? [...productList] : [];
          list = list.filter(e => e.id !== id);
          dispatch(updateProductList(list));
        }
      });
    }
  };

  findIconStyle = () => {
    const { picked, hover } = this.state;
    if (picked) {
      return 'notHoveringSelected';
    } if (hover) {
      return 'hovering';
    }
    return 'notHovering';
  };

  findDeleteIconStyle = () => {
    const { picked, hover } = this.state;
    if (picked && hover) {
      return 'picked';
    }
    return 'notPicked';
  }

  render() {
    const { product } = this.props;

    return (
      <Icon
        onClick={() => this.onClick(product.outOfStock)}
        onMouseEnter={() => this.mouseEnter(product.outOfStock)}
        onMouseLeave={this.mouseLeave}
        className={product.outOfStock ? 'productButton-inactive' : 'productButton'}
        pose={this.findIconStyle()}
      >
        <Cart />
        <Del className='deleteDiv' pose={this.findDeleteIconStyle()}>
          <Delete className='delete' />
        </Del>
      </Icon>
    );
  }
}

const mapStateToProps = state => ({
  productList: state.productListReducer.productList,
});

export default connect(mapStateToProps)(Productbutton);

const initialState = {
  productList: [],
  productListModal: false,
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_PRODUCTLIST':
      return {
        ...state,
        productList: action.value,
      };

    case 'TOGGLE_PRODUCTLIST_MODAL':
      return {
        ...state,
        productListModal: action.value,
      };

    default:
      return state;
  }
}

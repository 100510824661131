/* eslint-disable keyword-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable global-require */
/* eslint-disable camelcase */
/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import './styles.scss';
import posed from 'react-pose';
import PlusIcon from 'mdi-react/PlusIcon';
import { connect } from 'react-redux';

import PlusCircleOutlineIcon from 'mdi-react/PlusBoxIcon';
import MinusCircleOutlineIcon from 'mdi-react/MinusBoxIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import ChevronDown from 'mdi-react/ChevronDownIcon';
import ChevronUp from 'mdi-react/ChevronUpIcon';
import { updateProductList, toggleProductListModal } from '../../actions/productListActions';
import Api from '../../util/Api';
import Loader from '../Loader/Loader';

const Shade = posed.div({
  enter: {
    opacity: 1,
    overflow: 'visible',
    top: 0,
    height: '100%',
    transition: {
      height: { duration: 0 },
      top: { duration: 0 },
    },
    backgroundColor: 'rgba(255,255,255,.9)',
  },
  exit: {
    opacity: 0,
    overflow: 'hidden',
    top: '-800px',
    height: '0',
    transition: {
      height: { duration: 0, delay: 150 },
      top: { duration: 0, delay: 150 },
    },
    backgroundColor: 'rgba(255,255,255,0)',
  },
});

const CornerCloseDiv = posed.div({
  hovering: {
    rotate: '90deg',
  },
  notHovering: {
    rotate: '0deg',
  },
});

class ProductListModal extends Component {
  constructor() {
    super();
    this.state = {
      hover: false,
      pickedProduct: null,
      pdfEmail: '',
      name: '',
      address: '',
      zip: '',
      country: '',
      phone: '',
      company: '',
      last_name: '',
      show: false,
      hasPurchased: false,

      business_id: '',
      invoice_address: '',
      invoice_zip: '',
      invoice_country: '',

      editInvoice: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { productListModal } = this.props;
    if (nextProps.productListModal !== productListModal) {
      this.setState({ hasPurchased: false });
    }
  }

  closeModal = e => {
    const { dispatch } = this.props;
    if (e.target.id) {
      dispatch(toggleProductListModal(false));
    }
  }

  mouseEnter = () => {
    this.setState({
      hover: true,
    });
  }

  mouseLeave = () => {
    this.setState({
      hover: false,
    });
  }

  pickProduct = id => {
    const { pickedProduct } = this.state;
    if (pickedProduct === id) {
      this.setState({ pickedProduct: null });
    } else {
      this.setState({ pickedProduct: id });
    }
  }

  removeProduct = productID => {
    const { productList, dispatch } = this.props;
    let tmpProductList = [...productList];
    tmpProductList = tmpProductList.filter(product => (
      product.id !== productID
    ));
    dispatch(updateProductList(tmpProductList));
  }

  removePackage = packageID => {
    const { productList, dispatch } = this.props;
    let tmpProductList = [...productList];
    tmpProductList = tmpProductList.filter(product => (
      product.packageId !== packageID
    ));
    dispatch(updateProductList(tmpProductList));
  }

  countProducts = () => {
    const { productList } = this.props;
    let count = 0;
    let tmpCount = 0;

    if (productList.length > 0) {
      productList.forEach(product => {
        count = tmpCount + 1;
        tmpCount = count;
        if (product.packageId) {
          count = tmpCount + 1;
          tmpCount = count;
        }
      });
    }
    return count;
  }

  getPDF = event => {
    const { productList, dispatch } = this.props;
    const {
      pdfEmail, name, address, zip, country, phone, company, last_name,
      business_id, editInvoice, invoice_address, invoice_zip, invoice_country,
    } = this.state;
    event.preventDefault();

    this.setState({ loadingSendPDF: true }, async () => {
      try {
        await Api.products.sendPDF({
          products: productList,
          email: pdfEmail,
          name,
          address,
          zip,
          country,
          phone,
          company,
          last_name,

          business_id,
          invoice_address: editInvoice ? invoice_address : address,
          invoice_zip: editInvoice ? invoice_zip : zip,
          invoice_country: editInvoice ? invoice_country : country,

        });
        dispatch(updateProductList([]));
        this.setState({
          loadingSendPDF: false,
          pdfEmail: '',
          name: '',
          address: '',
          zip: '',
          country: '',
          phone: '',
          company: '',
          hasPurchased: true,
          business_id: '',
          invoice_address: '',
          invoice_zip: '',
          invoice_country: '',
        });
      } catch (err) {
        console.log(err);
        alert('Noe gikk galt ved sending av PDF');
        this.setState({ loadingSendPDF: false });
      }
    });
  }

  changeProductCount = (productID, count) => {
    const { productList, dispatch } = this.props;
    let productLisCopy = [...productList];
    productLisCopy.forEach(product => { // shitty way of doing this
      if (product.id === productID) {
        // eslint-disable-next-line no-param-reassign
        product.count += count;
      }
    });
    productLisCopy = productList.filter(p => p.count !== 0);
    dispatch(updateProductList(productLisCopy));
  }

  toggleShow= () => {
    const { show } = this.state;
    this.setState({ show: !show });
  }

  renderNokPrice = () => {
    const { productList } = this.props;
    let showNokPrice = true;

    if (productList.length > 0) {
      productList.forEach(prod => {
        if (typeof prod.priceNok === 'undefined') {
          showNokPrice = false;
        }
      });
    }

    if (showNokPrice === true) {
      return (
        <div>
          { productList && productList.reduce((accumulator, product) => accumulator
          + product.priceNok * product.count, 0)}
          {' '}
          NOK
        </div>
      );
    }
    return null;
  };

  toggleShowInvoiceState = () => {
    this.setState(state => ({
      editInvoice: !state.editInvoice,
      business_id: '',
      invoice_address: '',
      invoice_zip: '',
      invoice_country: '',
    }));
  };

  findFormDivStyle = () => {
    const { hasPurchased, show } = this.state;
    if(hasPurchased) {
      return { minHeight: '210px' };
    // eslint-disable-next-line no-else-return
    } else if(show) {
      return {
        position: 'absolute', top: '0', right: '0', left: '0', bottom: '0', backgroundColor: 'white',
      };
    } else {
      return { minHeight: '114px' };
    }
  };

  render() {
    const { productListModal, productList, dispatch } = this.props;
    const {
      pdfEmail, name, address, zip, country, phone, company, last_name,
      business_id, editInvoice, invoice_address, invoice_zip, invoice_country,
      loadingSendPDF, hover, hasPurchased, show,
    } = this.state;
    return (
      <Shade pose={productListModal ? 'enter' : 'exit'} id='productListModal-close' onClick={this.closeModal} className='productListModal'>
        <Loader cover state={loadingSendPDF} />
        <div id='productListModal-container' className='productListModal-container'>
          <div pose={productListModal ? 'enter' : 'exit'} className='productListModal-container-content'>
            <div className='productListModal-header'>
              <h1>
                Product list (
                {productList && productList.length === 1 ? `${this.countProducts()} Product` : `${this.countProducts()} Products`}
                )
              </h1>
              <div>
                <button onClick={() => dispatch(updateProductList([]))} type='button'>
                  <img src={require('../../images/trash.svg')} alt='' />
                    &nbsp;Empty list
                </button>
                <CornerCloseDiv pose={hover ? 'hovering' : 'notHovering'} id='productListModal-cornerButton' onClick={this.closeModal} className='closeModal'>
                  <PlusIcon id='productListModal-cornerIcon' className='closeIcon' />
                  <div onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} id='productListModal-corner' className='iconFiller' />
                </CornerCloseDiv>
              </div>
            </div>
            <div className='productListModal-body'>
              {productList && productList.map(product => (
                <div className='productListModal-item' key={`product-${product.id}`}>
                  <div className='productListModal-item-description-container'>
                    <p className='productListModal-item-description-brand'>{product.title.toUpperCase()}</p>
                  </div>
                  <div className='productListModal-item-details'>
                    <div className='productListModal-item-image-container'>
                      <img className='productListModal-item-image' src={product.mainImage.fields.file.url} alt='' />
                    </div>
                    <div style={{ marginRight: '10px' }} className='productListModal-item-count'>
                      <button
                        onClick={() => this.changeProductCount(product.id, 1)}
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                        type='button'
                      >
                        <PlusCircleOutlineIcon size={32} />
                      </button>
                      <p>{product.count}</p>
                      <button
                        onClick={() => this.changeProductCount(product.id, -1)}
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                        disabled={product.count === 1}
                        type='button'
                      >
                        <MinusCircleOutlineIcon size={32} />
                      </button>
                    </div>
                    <div className='productListModal-item-right'>
                      <button onClick={() => this.removeProduct(product.id)} type='button' className='trash-icon-cartItem'>
                        <img src={require('../../images/trash.svg')} alt='trash' />
                      </button>
                      <div>
                        <p>
                          €
                          {' '}
                          {product.price * product.count}
                        </p>
                        {product.priceNok ? (
                          <div className='modalPriceNok'>
                            {product.priceNok * product.count}
                            {' '}
                            NOK
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='productListModal-totalPrice'>
              <h1>Total price: </h1>
              <div>
                <h1>
                  €
                  {' '}
                  {productList
                  && productList.reduce((accumulator, product) => accumulator
                  + product.price * product.count, 0)}
                </h1>
                <div className='totalPriceNok'>{this.renderNokPrice()}</div>
              </div>
            </div>
            <div
              style={this.findFormDivStyle()}
              className='productListModal-email'
            >
              {
                hasPurchased === false
                  ? (
                    <form className='productListModal-email-form' onSubmit={this.getPDF}>
                      <button className='orderBtn' onClick={() => this.toggleShow()} type='button'>
                        Place Order
                        {' '}
                        {show ? <ChevronUp /> : <ChevronDown />}
                      </button>
                      <div className={show === true ? 'show' : 'hide'}>

                        <input value={pdfEmail} onChange={event => { this.setState({ pdfEmail: event.target.value }); }} required name='email' type='email' placeholder='E-mail' />
                        <input value={company} onChange={event => { this.setState({ company: event.target.value }); }} required name='company' type='text' placeholder='Company name' />
                        <input value={business_id} onChange={event => { this.setState({ business_id: event.target.value }); }} name='business_id' type='text' placeholder='BUSINESS ID / VAT NR' />
                        <input value={name} onChange={event => { this.setState({ name: event.target.value }); }} required name='name' type='text' placeholder='Name' />
                        <input value={phone} onChange={event => { this.setState({ phone: event.target.value }); }} required name='phone' type='tel' placeholder='Phone number' />
                        <input value={address} onChange={event => { this.setState({ address: event.target.value }); }} required name='adress' type='text' placeholder='Address' />
                        <input value={zip} onChange={event => { this.setState({ zip: event.target.value }); }} required name='zip' type='text' placeholder='Zip code' />
                        <input value={country} onChange={event => { this.setState({ country: event.target.value }); }} required name='country' type='text' placeholder='Country' />
                        <input value={last_name} onChange={event => { this.setState({ last_name: 'altered' }); }} name='last_name' type='text' className='productListModal-last_name' />
                        <div className='checkMarkAndTitleBox'>
                          <input type='checkbox' onClick={() => this.toggleShowInvoiceState()} className='invoiceCeckBox' />
                          <button type='button' onClick={() => this.toggleShowInvoiceState()} className='invoiceCheckBoxText'>
                            Use different invoice address
                          </button>

                        </div>
                        <div className={editInvoice ? 'showEditInvoice' : 'hideEditInvoice'}>
                          <input value={invoice_address} onChange={event => { this.setState({ invoice_address: event.target.value }); }} name='invoice_address' type='text' placeholder='Invoice Address' />
                          <input value={invoice_zip} onChange={event => { this.setState({ invoice_zip: event.target.value }); }} name='invoice_zip' type='text' placeholder='Invoice Zip code' />
                          <input value={invoice_country} onChange={event => { this.setState({ invoice_country: event.target.value }); }} name='invoice_country' type='text' placeholder='Invoice Country' />
                        </div>

                        <button disabled={pdfEmail === '' || company === '' || name === '' || phone === '' || address === '' || zip === '' || country === ''} type='submit'>Send Order</button>
                      </div>
                    </form>
                  )
                  : (
                    <div style={{
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderTop: '1px solid #E5E5E5', height: '100%',
                    }}
                    >
                      <CheckboxMarkedCircleIcon size={64} color='#84ce52' />
                      <h3 style={{ textAlign: 'center' }}>Thank you for your purchase!</h3>
                      <p style={{ textAlign: 'center' }}>We've sent you an email confirming your order</p>
                    </div>
                  )
              }
            </div>
            <div id='productListModal-closeButton' className='productListModal-close'>
              Close
            </div>
          </div>
        </div>
      </Shade>
    );
  }
}

const mapStateToProps = state => ({
  productList: state.productListReducer.productList,
  productListModal: state.productListReducer.productListModal,
});

export default connect(mapStateToProps)(ProductListModal);

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Product from '../../components/Product/Product';
import './styles.scss';
import { getProductsByCategoryId } from '../../util/Api2';
import Loader from '../../components/Loader/Loader';

const Home = ({ mainMenuCategoryId }) => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [productsInTotal, setProductsInTotal] = useState(0);

  // Prev Page and category refs
  const prevCountRef = useRef();
  useEffect(() => {
    prevCountRef.current = page;
  });
  const prevPage = prevCountRef.current;

  // Reset page when category is changed
  useEffect(() => {
    setPage(0);
    setProducts([]);
  }, [mainMenuCategoryId]);

  // get products for initial load
  useEffect(() => {
    if (mainMenuCategoryId !== 0 && page === 0 && products.length === 0) {
      getProducts();
    }
  }, [products]);

  // handle page change
  useEffect(() => {
    const getProds = async () => {
      if ((page && (prevPage !== page) && mainMenuCategoryId !== 0)
        || page === 0) {
        setLoading(true);
        try {
          await getProducts();
        } catch (err) {
          setLoading(false);
          console.log(err);
        }
      }
    };
    getProds();
  }, [page]);

  const getProducts = async () => {
    getProductsByCategoryId(mainMenuCategoryId, page).then(data => {
      if (page !== 0) {
        const resProductsConcat = products.concat(data.items);
        setLocalProductState(resProductsConcat, false, data.total);
        checkScrollHeight();
      }
      if (page === 0) {
        const resProductsConcat = products.concat(data.items);
        setLocalProductState(resProductsConcat, false, data.total);
        checkScrollHeightInitial();
      }
    });
  };

  const setSkip = () => {
    setPage(page + 9);
  };

  const checkScrollHeight = () => {
    const productElement = document.getElementById('products');
    if (window.innerHeight > productElement.scrollHeight
       && (productsInTotal > products.length
      || (productsInTotal === 0 && products.length !== 0))
    ) {
      setSkip();
    }
  };

  const checkScrollHeightInitial = () => {
    const productElement = document.getElementById('products');
    if (window.innerHeight > productElement.scrollHeight && mainMenuCategoryId !== 0) {
      setSkip();
    }
  };

  const setLocalProductState = (prods, loadStatus, total) => {
    if (prods.length > 0) {
      setProducts(prods);
    }

    setLoading(loadStatus);
    setProductsInTotal(total);
  };

  const updateFilterPosition = event => {
    if (
      (event.target.scrollHeight - event.target.scrollTop - window.innerHeight <= 0
      && productsInTotal > products.length && products.length !== 0 && loading === false)
      || (productsInTotal === 0 && products.length === 0)
    ) {
      setSkip();
    }
  };

  return (
    <div id='home' className='home' onScroll={updateFilterPosition}>
      <div className='main-center'>
        <div className='main'>
          <div className='productWrap' id='products'>
            {products
            && products.map(product => (
              <Product
                key={`product${product.sys.id}`}
                token='foobar'
                product={product.fields}
                productID={product.sys.id}
                id={product.sys.id}
              />
            ))}
            <div className='filler'>
              <Loader
                state={loading}
                cover={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  products: state.productReducer.products,
  mainMenuCategoryId: state.mainMenuReducer.mainMenuCategoryId,
});

export default connect(mapStateToProps)(Home);

/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cart from 'mdi-react/CartIcon';
import posed from 'react-pose';
import { updateProductList, toggleProductListModal } from '../../actions/productListActions';
import './styles.scss';

const Content = posed.div({
  closed: {
    height: 0,
    opacity: 0,
  },
  open: {
    height: '50px',
    opacity: 1,
  },
});

class Porductlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { productList } = this.props;
    const { show } = this.state;

    if (productList.length > 0 && show === false && show === false) {
      this.setState({
        show: true,
      });
    } else if (prevState.show !== show && show === true && productList.length === 0) {
      this.setState({
        show: false,
      });
    }
  }

  emptryProductList = () => {
    const { dispatch } = this.props;
    dispatch(updateProductList([]));
  }

  toggleProductModal = () => {
    const { dispatch } = this.props;
    dispatch(toggleProductListModal(true));
  }

  countProducts = () => {
    const { productList } = this.props;
    let count = 0;
    let tmpCount = 0;
    if (productList.length > 0) {
      // eslint-disable-next-line no-unused-vars
      productList.forEach(product => {
        count = tmpCount + 1;
        tmpCount = count;
      });
    }

    return count;
  };

  renderNokPrice = () => {
    const { productList } = this.props;
    let showNokPrice = true;
    if (productList.length > 0) {
      productList.forEach(prod => {
        if (typeof prod.priceNok === 'undefined') {
          showNokPrice = false;
        }
      });
    }

    if (showNokPrice === true) {
      return (
        <div className='totalNokPriceBottom'>
          (
          {productList.reduce((accumulator, product) => accumulator
          + product.priceNok * product.count, 0)}
          {' '}
          NOK)
        </div>
      );
    }
    return null;
  };

  render() {
    const { productList } = this.props;
    const { show } = this.state;
    if (productList && productList.length > 0) {
      return (
        <Content pose={show ? 'open' : 'closed'} className='productList'>
          <div className='priceAndAmount'>
            <div className='totalPricesContainer'>
              <p className='price'>
                Total: €
                {' '}
                {productList.reduce((accumulator, product) => accumulator + (product.price), 0)}
              </p>
              {this.renderNokPrice()}
            </div>
          </div>
          <div className='buttons'>
            <button onClick={this.toggleProductModal} className='showList' type='button'>
              <Cart />
              <p className='showListText'>Show cart</p>
            </button>
          </div>
        </Content>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  productList: state.productListReducer.productList,
});

export default connect(mapStateToProps)(Porductlist);

import React, { useState, useEffect } from 'react';
import posed from 'react-pose';
import { connect } from 'react-redux';
import './styles.scss';
import Productbutton from '../Productbutton/Productbutton';
import ProductCard from '../ProductCard/ProductCard';

const Opac = posed.div({
  picked: {
    opacity: 0.5,

  },
  notPicked: {
    opacity: 1,
  },
});

const Product = ({
  productList, id, product, productID,
}) => {
  const [picked, setPicked] = useState(false);
  const [expandPic, setExpandPic] = useState(false);

  useEffect(() => {
    if (productList.lenght > 0) {
      productList.forEach(productItem => {
        if (productItem.id === id) {
          setPicked(true);
        }
      });
    }
  }, [id, productList]);

  const pick = bool => setPicked(bool);

  const togglePicture = () => {
    setExpandPic(!expandPic);
  };

  return (
    <div className='productLayout'>
      {product
        && (
        <div className='product'>
          <figure className='figure'>
            <div className='imageDiv'>
              { expandPic
                ? (
                  <>
                    <div className='expandPicModal'>
                      <div className='expandPicModal-container'>
                        <ProductCard
                          productID={productID}
                          togglePicture={togglePicture}
                          link={product.mainImage.fields.file.url}
                          text={product}
                        />
                      </div>
                    </div>
                  </>
                ) : null }
              <Opac pose={picked ? 'picked' : 'notPicked'}>
                { product?.mainImage?.fields?.file?.url ? (
                  <button onClick={togglePicture} type='button' className='productlist-image'>
                    <img
                      className='image'
                      src={`${product.mainImage.fields.file.url}?q=70&w=250`}
                      alt='product_image'
                    />
                  </button>
                  ) : null}
              </Opac>
              <Productbutton
                id={id}
                pick={pick}
                product={product}
              />
              {product.new ? <div className='newBuble'>NEW!</div> : null}
              {product.outOfStock ? <div className='outOfStockBuble'>SOLD OUT</div> : null}
            </div>
            <figcaption className='infoContainer'>
              <div>
                <Opac pose={picked ? 'picked' : 'notPicked'} className='brand'>
                  { product.title
                    ? product.title
                    : 'n/a'}
                </Opac>
              </div>
              <Opac pose={picked ? 'picked' : 'notPicked'} className='price'>
                <div className='priceContainer'>
                  <div className='bothPriceContainer '>
                    <span className='normal'>
                      €
                      {' '}
                      {product.price}
                    </span>
                    {product.priceNok
                      ? (
                        <span className='priceNok'>
                          {product.priceNok}
                          {' '}
                          NOK
                        </span>
                      )
                      : null}
                  </div>
                  <div className='modelCont'>
                    <span className='model'>{product.shortDescripiton}</span>
                  </div>
                </div>
              </Opac>
            </figcaption>
          </figure>
        </div>
        )}
    </div>
  );
};

const mapStateToProps = ({ productListReducer }) => ({
  productList: productListReducer.productList,
});

export default connect(mapStateToProps)(Product);

/* eslint-disable no-underscore-dangle */
import { createStore, combineReducers } from 'redux';
import productReducer from './reducers/productReducer';
import productListReducer from './reducers/productListReducer';
import mainMenuReducer from './reducers/mainMenuReducer';
import { loadState, saveState } from './localStorage';

const presistedState = loadState();
const rootReducer = combineReducers({
  productReducer,
  productListReducer,
  mainMenuReducer,
  presistedState,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
store.subscribe(() => {
  saveState({
    productListReducer: store.getState().productListReducer,
  });
});

export default store;

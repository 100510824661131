const initialState = {
  mainMenuCategoryId: 0,
  mainMenuCategoryName: '',
};

export default function mainMenuReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_MAIN_MENU_CATEGORY_ID':
      return {
        ...state,
        mainMenuCategoryId: action.value,
      };

    case 'UPDATE_MAIN_MENU_CATEGORY_NAME':
      return {
        ...state,
        mainMenuCategoryName: action.value,
      };

    default:
      return state;
  }
}

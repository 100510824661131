/* eslint-disable no-param-reassign */
/* eslint-disable no-redeclare */
export function updateProductList(value) {
  value = value.map(v => { v.count = v.count === undefined ? 1 : v.count; return v; });
  return {
    type: 'UPDATE_PRODUCTLIST',
    value,
  };
}

export function toggleProductListModal(state) {
  return {
    type: 'TOGGLE_PRODUCTLIST_MODAL',
    value: state,
  };
}

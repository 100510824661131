export function updateMainMenuCategoryId(value) {
  return {
    type: 'UPDATE_MAIN_MENU_CATEGORY_ID',
    value,
  };
}

export function updateMainMenuCategoryName(value) {
  return {
    type: 'UPDATE_MAIN_MENU_CATEGORY_NAME',
    value,
  };
}

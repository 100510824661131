export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return {
        productListReducer: { productList: [] },
        packageReducer: { packageId: null, packageProductLink: [], packageProduct: null },
      };
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.log('loadState ERROR', err);
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    console.log('saveState ERROR', err);
  }
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSplashScreen, getCategories } from '../../util/Api2';
import { updateMainMenuCategoryId } from '../../actions/mainMenuActions';
import './styles.scss';

const SplashScreen = props => {
  const [Url, setUrl] = useState('');
  const [showMenu, setShowMenu] = useState('');
  const [menuCats, setMenuCats] = useState([]);
  const history = useHistory();

  const getSplashUrl = async () => {
    try {
      const resUrl = await getSplashScreen();
      setUrl(`${resUrl[0].fields.picture.fields.file.url}`);

      setShowMenu(resUrl[0].fields.showMenu);
    } catch (err) {
      console.log('CERR', err);
    }
  };

  const getMenuCategories = async () => {
    try {
      const categories = await getCategories();
      setMenuCats(categories);
    } catch (err) {
      console.log('CERR', err);
    }
  };

  useEffect(() => {
    getSplashUrl();
    getMenuCategories();
  }, []);

  const sectionStyle = {
    height: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundImage: `url(${Url}?w=2048)`,
  };

  const onBackgroundClick = () => {
    history.push('/shop');
  };

  const onMenuItemPicked = id => {
    props.dispatch(updateMainMenuCategoryId(id));
  };

  const renderMenu = () => {
    if (menuCats !== [] && showMenu === true) {
      return menuCats.map(cat => (
        <button key={cat.sys.id} className='menuItem' onClick={() => onMenuItemPicked(cat.sys.id)} type='button'>
          {cat.fields.title.toUpperCase()}
        </button>
      ));
    }
    return null;
  };

  return (
    <button onClick={() => onBackgroundClick()} type='button' className='splashButton'>
      <section style={sectionStyle}>
        <div className='splashMenuContainer'>
          {renderMenu()}
        </div>
      </section>
    </button>
  );
};

const mapStateToProps = state => ({
  mainMenuCategoryId: state.mainMenuReducer.mainMenuCategoryId,
});

export default connect(mapStateToProps)(SplashScreen);

/* eslint-disable global-require */
import React, { Component } from 'react';
import './styles.scss';
import { Navbar } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCategories } from '../../util/Api2';
import { updateMainMenuCategoryId, updateMainMenuCategoryName } from '../../actions/mainMenuActions';
import { updateProductList } from '../../actions/productListActions';

class Topbar extends Component {
  showMenu = this.showMenu.bind(this);

  closeMenu = this.closeMenu.bind(this);

  constructor() {
    super();
    this.state = { showMenu: false };
  }

  componentDidMount() {
    this.getCategories();
  }

    getCategories = async () => {
      const { mainMenuCategoryId } = this.props;
      try {
        const categories = await getCategories();
        this.setState({
          categories,
        },
        () => {
          if (mainMenuCategoryId === 0) {
            this.onChangeMainMenuItem(0);
          }
        });
      } catch (err) {
        console.log('CERR', err);
      }
    }

  onChangeMainMenuItem = index => {
    const { dispatch } = this.props;
    const { categories } = this.state;
    dispatch(updateMainMenuCategoryName(categories[index].fields.title));
    dispatch(updateMainMenuCategoryId(categories[index].sys.id));
  }

  deleteProductCart() {
    const { dispatch } = this.props;
    dispatch(updateProductList([]));
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  render() {
    const { showMenu, categories } = this.state;
    const { mainMenuCategoryName } = this.props;
    return (
      <Navbar className='navbar'>
        <div className='main-top'>
          <Link to='/' onClick={() => this.deleteProductCart()}>
            <img src={require('../../images/cardsome.png')} alt='cardsome' />
          </Link>
          <div className='dropDownMeniu'>
            <button onClick={this.showMenu} className='dropDownMenuButton' type='button'>
              {'MENU'}
              {' '}
              {showMenu ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </button>

            {
          showMenu
            ? (
              <div className='menu'>
                { !!categories
              && categories.map((data, index) => (
                <button
                  className='dropPick'
                  key={`Rendermenu-${data.fields.sequence}`}
                  onClick={() => this.onChangeMainMenuItem(index)}
                  type='button'
                >
                  <div className={data.fields.title === mainMenuCategoryName ? 'pickedItem' : null}>
                    {data.fields.title.toUpperCase()}
                  </div>
                </button>
              ))}
              </div>
            )
            : (
              null
            )
        }
          </div>
          <div className='meniuItems'>
            { !!categories
              && categories.map((data, index) => (
                <button
                  key={`Rendermenu-${data.fields.sequence}`}
                  onClick={() => this.onChangeMainMenuItem(index)}
                  type='button'
                >
                  <div className={data.fields.title === mainMenuCategoryName ? 'pickedItem' : null}>
                    {data.fields.title.toUpperCase()}
                  </div>
                </button>
              ))}
          </div>
        </div>
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  mainMenuCategoryName: state.mainMenuReducer.mainMenuCategoryName,
  mainMenuState: state.mainMenuReducer.mainMenuState,
  mainMenuCategoryId: state.mainMenuReducer.mainMenuCategoryId,
  productList: state.productListReducer.productList,
});

export default connect(mapStateToProps)(Topbar);

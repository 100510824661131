/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import PlusIcon from 'mdi-react/PlusIcon';
import './styles.scss';
import Cart from 'mdi-react/CartIcon';
import posed from 'react-pose';
import { connect } from 'react-redux';
import { updateProductList } from '../../actions/productListActions';

const Icon = posed.div({
  hovering: {
    top: '-1px',
    color: '#ffffff',
    backgroundColor: '#000000',
    boxShadow: '0 3px 3px rgba(36, 43, 73, 0.5)',
    transition: { duration: 100 },
  },
  notHovering: {
    top: '1px',
    color: '#242b49',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 0 rgba(36, 43, 73, 0.5)',
    transition: { duration: 100 },
  },
  notHoveringSelected: {
    top: '1px',
    color: '#ffffff',
    backgroundColor: '#000000',
    boxShadow: '0 0 0 rgba(36, 43, 73, 0.5)',
    transition: { duration: 100 },
  },
});

const CornerCloseDiv = posed.div({
  hovering: {
    rotate: '90deg',
  },
  notHovering: {
    rotate: '0deg',
  },
});

class ProductCardModal extends Component {
  constructor(props) {
    super(props);
    const { productList, productID } = this.props;
    this.state = {
      cartPicked: productList
            && productList.find((product => product.id === productID))
            !== undefined,
      hover: false,
    };
  }

    handlePicked = outOfStock => {
      const {
        productList, productID, dispatch, text,
      } = this.props;
      if (!outOfStock) {
        const index = productList.findIndex((product => product.id === productID));
        if (index !== -1) {
          const updatedProductList = [...productList].filter((product => product.id !== productID));
          dispatch(updateProductList(updatedProductList));
          this.setState({
            cartPicked:
            updatedProductList.find((product => product.id === productID)) !== undefined,
          });
        } else {
          const tmpProdductList = [...productList];
          tmpProdductList.push({ id: productID, count: 1, ...text });
          dispatch(updateProductList(tmpProdductList));
          this.setState({
            cartPicked:
            tmpProdductList.find((product => product.id === productID)) !== undefined,
          });
        }
      }
    }

     mapDesc = array => {
       // eslint-disable-next-line consistent-return
       const mappedArray = array.map(arr => arr.content.map(second => {
         if (second.value) {
           let bold = false;
           let italic = false;
           if (second.marks) {
             second.marks.map(mark => {
               if (mark.type === 'bold') {
                 bold = true;
               }
               if (mark.type === 'italic') {
                 italic = true;
               }
             });
           }

           const checkStyle = () => {
             const editStyle = {};
             if (bold === true) {
               editStyle.fontWeight = 'bold';
             }
             if (italic === true) {
               editStyle.fontStyle = 'italic';
             }
             return editStyle;
           };

           return <div className='expandCard-description' key={second.value} style={checkStyle()}>{second.value}</div>;
         }
         if (second.content) {
           return (
             <div className='expandCard-description' key={second.content[0].content[0].value}>
               {' '}
               -
               {' '}
               {second.content[0].content[0].value}
             </div>
           );
         }
         if (second.content) {
           second.content.map(third => {
             if (third.content) {
               return <div className='expandCard-description' key={third.content[0].value}>{third.content[0].value}</div>;
             }
           });
         }
       }));

       return mappedArray;
     };

  mouseEnter = outOfStock => {
    if (!outOfStock) {
      this.setState({
        cartHover: true,
      });
    }
  }

  mouseEnterExit = () => {
    this.setState({
      hover: true,
    });
  };

  mouseLeaveExit = () => {
    this.setState({
      hover: false,
    });
  };

  findShopIconStyle = () => {
    const { cartPicked, cartHover } = this.state;
    if (cartPicked) {
      return 'notHoveringSelected';
    } else if (cartHover) {
      return 'hovering';
    }
    return 'notHovering';
  }

  render() {
    const { link, text, togglePicture } = this.props;
    const { cartPicked, hover } = this.state;
    return (
      <>
        <div className='expandCard'>
          <div className='expandCardsHalf expandCardHalfImage'>
            <img src={`${link}?&w=550`} className='expandPic' alt={text.title} />
          </div>
          <div className='dividerLine' />
          <div className='expandCardHalf'>
            <div className='closeIconContainer'>
              <CornerCloseDiv pose={hover ? 'hovering' : 'notHovering'} id='productListModal-cornerButton' onClick={togglePicture} className='closeModal'>
                <PlusIcon id='productListModal-cornerIcon' className='closeIcon' />
                <div onMouseEnter={this.mouseEnterExit} onMouseLeave={this.mouseLeaveExit} id='productListModal-corner' className='iconFiller' />
              </CornerCloseDiv>
            </div>
            <div className='expandCard-TextContainer'>
              <div className='expandCard-brandName'>{text.title}</div>
              <div className='expandCard-modelName'>{text.shortDescripiton}</div>
              <div className='expandCard-modelPrice'>
                €
                {' '}
                {text.price}
              </div>
              <button
                type='button'
                onClick={() => this.handlePicked(text.outOfStock)}
                style={text.outOfStock
                  ? {
                    display: 'flex', alignItems: 'center', marginTop: '15px', marginLeft: '15px', opacity: '50%', backgroundColor: 'transparent', borderWidth: '0',
                  }
                  : {
                    display: 'flex', alignItems: 'center', marginTop: '15px', marginLeft: '15px', backgroundColor: 'transparent', borderWidth: '0',
                  }}
              >
                <Icon
                  style={{ position: 'unset', marginRight: '10px' }}
                  onMouseEnter={() => this.mouseEnter(text.outOfStock)}
                  onMouseLeave={() => this.setState({ cartHover: false })}
                  className='productButton'
                  pose={this.findShopIconStyle()}
                >
                  <Cart />
                </Icon>
                { cartPicked ? 'In cart' : 'Add to cart'}
              </button>
              {text.outOfStock ? <div className='soundOutMessage-card'>SOLD OUT </div> : null}
              <div className='expandCard-descriptionName'>{text.description ? 'PRODUCT DETAILS' : null}</div>
              { typeof text.description !== 'undefined' ? this.mapDesc(text.description.content) : null}
            </div>
            <button type='button' onClick={togglePicture} className='productCardModal-close desktop'>Close</button>
          </div>
          <button type='button' onClick={togglePicture} className='productCardModal-close mobile'>Close</button>

        </div>
      </>
    );
  }
}
const mapStateToProps = ({ productListReducer }) => ({
  productList: productListReducer.productList,
});

export default connect(mapStateToProps)(ProductCardModal);

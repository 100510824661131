/* eslint-disable no-async-promise-executor */
const contentful = require('contentful');

const client = contentful.createClient({
  space: 'xuga0ccjytma',
  accessToken: 'RmS2PgpSk5XhNQMzmdJKKd31kYWIbGF5BZku4gKbIOk',
});

export const getAllRecords = async () => new Promise(async (resolve, reject) => {
  await client.getEntries()
    .then(response => resolve(response.items))
    .catch(err => reject(err));
});

export const getCategory = async categoryName => new Promise(async (resolve, reject) => {
  await client.getEntries({
    content_type: 'category',
    'fields.title': categoryName,
  })
    .then(response => resolve(response.items))
    .catch(err => reject(err));
});

export const getCategories = async () => new Promise(async (resolve, reject) => {
  await client.getEntries({
    content_type: 'category',
    order: 'fields.sequence',
  })
    .then(response => resolve(response.items))
    .catch(err => reject(err));
});

export const getProductsByCategoryId = async (catId, skip) => new Promise(
  async (resolve, reject) => {
    await client.getEntries({
      content_type: 'product',
      'fields.productCategory.sys.id': catId,
      skip,
      limit: 9,
      order: 'fields.priority',
    })
      .then(response => resolve(response))
      .catch(err => reject(err));
  },
);

export const getAllProducts = async () => new Promise(async (resolve, reject) => {
  await client.getEntries({
    content_type: 'product',
  })
    .then(response => resolve(response.items))
    .catch(err => reject(err));
});

export const getSplashScreen = async () => new Promise(async (resolve, reject) => {
  await client.getEntries({
    content_type: 'splashScreen',
  })
    .then(response => resolve(response.items))
    .catch(err => reject(err));
});

import React, { Component } from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import SplashScreen from '../pages/SplashScreen/SplashScreen';
import Home from '../pages/Home/Home';
import Topbar from '../components/Topbar/Topbar';
import Productlist from '../components/Productlist/Porductlist';
import ProductListModal from '../components/ProductListModal/ProductListModal';
import '../styles/layout.scss';

class PrivateRoute extends Component {
  constructor() {
    super();
    this.state = {
      isAuthenticated: true,
    };
  }

  render() {
    const { loading, isAuthenticated } = this.state;
    return (
      <Route
        render={props => !loading
          && (
            isAuthenticated ? (
              <this.props.component />
            ) : (
              <Redirect to={{
                pathname: '/',
                state: { from: props.location },
              }}
              />
            ))}
      />
    );
  }
}

const Pages = () => (
  <Switch>
    <Route exact path='/shop' component={Home} />
    <Route path='/' component={SplashScreen} />
  </Switch>
);

const wrappedRoutes = () => (
  <div className='layoutWrapper'>
    <div className='layout'>
      <Topbar className='topbar' />
      <ProductListModal />
      <Route exact path='/shop' component={Pages} />
      <Productlist />
    </div>
  </div>
);

const Router = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute exact path='/shop' component={wrappedRoutes} />
      <PrivateRoute path='/' component={SplashScreen} />
    </Switch>
  </BrowserRouter>
);

export default Router;

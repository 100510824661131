const API_BASE_URL = '';
const API_BASE_URL_CARDSOME = '//cardsome-api-dot-vinnvinn-test.appspot.com/api';
const AKENEO_API_URL = ''

async function handleErrors(response) {
	const resJson = await response.json();
	if (!response.ok) {
		throw resJson;
	}
	return resJson;
}


const makeRequest = async (method, url, auth = true, payload = {}) => {
	const options = {
		method,
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		mode: 'cors',
	};
	if (method !== 'GET') {
		options.body = JSON.stringify(payload);
	}
	return new Promise(async (resolve, reject) => {
		await fetch(url, options)
			.then(handleErrors)
			.then(response => resolve(response) )
			.catch(err => reject(err) );
	});
};

export default {
	menu: {
		getCategories: () => makeRequest('GET', `//${API_BASE_URL}/categories?with_children_recursive=1`),
	},
	products: {
		getProductsByCategory: (categoryID, page) => makeRequest('GET', `${API_BASE_URL}/categories/${categoryID}/products?pageSize=9&page=${page}`),
		getProductById: (id) => makeRequest('GET', `${API_BASE_URL}/products/${id}`), 
		getProductsByCategoryAndPrice: (categoryID, page, price) => makeRequest('GET', `${API_BASE_URL}/categories/${categoryID}/products?sort=${price}&pageSize=9&page=${page}`),
		sendPDF: payload => makeRequest('POST', `${API_BASE_URL_CARDSOME}/send_pdf`, true, payload),
	},
	package: {
		getPackageByProductId: (id) => makeRequest('GET',`${API_BASE_URL}/products/${id}/packages`),
	},
}